@import '../../../assets/scss/global.scss';

.custom-provider-information {
	background-color: $charcoal;
	border: 1px solid;
	border-color: $transparent-black;
	.provider-container {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 16px;
        max-width: 400px;
        margin-left: 16px;
	}
	.privider-label {
		font-size: 16px;
	}
}
