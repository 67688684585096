.disabled {
  cursor: 'not-allowed';
  opacity: '0.5';
}

.primary-btn {
  height: 33px;
  border-radius: 5px;
  background: var(--primary-shades-90, #1b5984);
  color: white;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
}

.primary-light-with-border-btn {
  height: 33px;
  border-radius: 5px;
  border: 1px solid #a9a1a1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: white;
  font-weight: 600;
  color: rgb(99, 104, 102);
  font-weight: 600;
  align-items: center;
  width: 100%;
}

.button-with-icon {
  display: flex;
  column-gap: 10px;
  padding: 10px 14px;
  align-items: center;
  height: 33px;
}

.light-with-no-border {
  white-space: nowrap;
  font-weight: 600;
  color: rgb(99, 104, 102);
  padding: 5px 10px;
}

.button-with-light-color {
  height: 32px;
  border: 1px solid #1b5984;
  border-radius: 5px;
  background: var(--primary-shades-90, #ccecff80);
  color: #1b5984;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  opacity: 1;
  padding: 16px 16px 16px 10px;
  font-family: 'roboto';
}

.button-with-light-color-no-icon {
  height: 33px;
  border-radius: 5px;
  border: 1px solid #1b5984;
  background: var(--primary-shades-90, #ccecff80);
  color: #1b5984;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  opacity: 1;
  padding: 5px 6px;
}

.button-with-gray-color {
  height: 33px;
  border-radius: 5px;
  background: var(--primary-shades-90, #1a1a1a66);
  color: white;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  padding: 16px 16px 16px 10px;
}

.button-with-light-color-bg {
  height: 32px;
  border: 1px solid #0097f0;
  border-radius: 5px;
  background: #ccecff;
  color: #1b5984;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  opacity: 1;
  padding: 16px 16px 16px 10px;
}

.button-with-red-color-bg {
  height: 32px;
  border: 1px solid #ff3939;
  border-radius: 5px;
  background: #ff39390d 0% 0% no-repeat padding-box;
  color: red;
  white-space: nowrap;
  font-weight: 600;
  width: 100%;
  align-items: center;
  opacity: 1;
  padding: 16px 16px 16px 10px;
}

.primary-light-with-border-btn-toogle {
  height: 33px;
  border-radius: 5px;
  border: 1px solid #a9a1a1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: white;
  font-weight: 600;
  color: rgb(99, 104, 102);
  font-weight: 600;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}
.light-without-border-btn {
  white-space: nowrap;
  font-weight: 600;
  color: rgb(99, 104, 102);
  background: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 19px 10px;
  border-radius: 5px;
}

.button-with-box-shadow {
  height: 38px;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 5px;
  color: #1a1a1acc;
  white-space: nowrap;
}

.button-without-bg-color-toggle {
    height: 38px;
    border-radius: 25px;
    border: 1px solid #1b5984;
    color: #1b5984;
    white-space: nowrap;
    font-weight: 500;
    align-items: center;
    opacity: 1;
    padding: 0px 6px;
}

.button-without-any-color-toggle {
    height: 38px;
    color: #1A1A1A99;
    white-space: nowrap;
    font-weight: 500;
    align-items: center;
    opacity: 1;
    padding: 0px 6px;
}