@import '../../../assets/scss/global.scss';

.toggle-button {
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 5px;
	opacity: 1;
	background-color: $white-color;
	box-shadow: 0px 0px 6px #00000029;
	width: 110px;
	height: 38px;
}

.active {
	color: white;
	background-color: $cyan-blue;
}
