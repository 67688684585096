@import '../../../assets/scss/global.scss';

.custom-patient-details {
	background-color: $charcoal;
	border: 1px solid;
	border-color: $transparent-black;
	.emergency-contact-wrapper {
		flex-wrap: wrap;
	}
}
