.share-view {
  display: none;
  &.share-view-in-sharing {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-right: 1px solid #333;
  }
  &.share-view-original {
    display: block;
  }
  .share-view-viewport {
    width: 100%;
    height: 100%;
    &.share-view-viewport-original {
      max-width: initial;
      cursor: move;
    }
    &.share-view-viewport-in-control {
      cursor: default;
    }
    .share-view-canvas {
      height: 100%;
      width: 100%;
    }
  }
}
