input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #8d8d8d;
}

input:focus {
  outline: none;
  //   box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.hide-spinners {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

input:disabled {
  cursor: not-allowed;
  background: #f6f4f4 0% 0% no-repeat padding-box;
  box-shadow: none;
  border: 1px solid rgb(207, 204, 204) !important;
}
.input-textBox {
  border: none !important;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  max-height: 38px !important;
}
.text-label {
  color: #1A1A1A !important;
  margin-bottom: 6px !important;
  font-size: 14px !important;
  font-weight: 500; 
}
.form-label {
  font-size: 14px !important;
}
