@import '../../../assets/scss/global.scss';

.custom-patient-details {
	background-color: $charcoal;
	border: 1px solid;
	border-color: $transparent-black;
	.preferences-wrapper {
		flex-wrap: wrap;
		.preferences-container {
			width: 100%;
		}
	}
}
