@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@media print {
	.print-hidden {
		display: none !important; /* Hides the element when printing */
	}
	body {
		-webkit-print-color-adjust: exact; /* For Webkit browsers like Chrome */
        print-color-adjust: exact; /* Standard property */
	}
}

.calendar-css {
    .rbc-header {
        padding-bottom: 5px !important;
        color: theme("colors.rbga-3a3a3a99") !important;
    }
    .rbc-month-header {
        padding-top: 10px !important;
    }
    .rbc-off-range-bg {
        background: white;
        height: 100%;
        opacity: 1;
        color: white;
        z-index: 10;
        cursor: auto !important;
    }

    .rbc-month-view {
        border: none !important;
        border-top: 1px solid theme("colors.rgba-00000029") !important;
    }

    .rbc-date-cell {
        text-align: center !important;
        padding-right: 0px !important;
        padding-top: 10px;
    }

    .rbc-button-link {
        color: theme("colors.rbga-3a3a3a99") !important;
        font-weight: 600;
    }
    .rbc-show-more {
        color: theme("colors.met-primary") !important;
        padding-top: 5px !important;
    }
    .rbc-month-row {
        overflow: visible !important;
    }

    .rbc-row-segment {
        text-align: -webkit-center !important;
        cursor: pointer;
    }
    .rbc-day-bg {
        cursor: pointer;
    }
}
