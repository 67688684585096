.chat-message-item {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	padding: 5px 20px;
	&.myself {
		flex-direction: row-reverse;
		.chat-message-avatar {
			margin-right: 0;
			margin-left: 16px;
		}
	}
	.chat-message-avatar {
		width: 50px;
		height: 50px;
		color: #333;
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px;
		.chat-message-avatar-img {
			width: 26px;
			height: 26px;
			border-radius: 6px;
		}
	}
	.chat-message-content {
		flex-grow: 1;
		.chat-timestamp {
			color: #4c4c4c99;
			font-size: 10px;
		}
		.chat-sender {
			color: #4c4c4c;
			font-size: 12px;
			margin-left: 5px;
		}
		.chat-message-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.myself {
				flex-direction: row-reverse;
				.chat-message-receiver {
					text-align: right;
				}
			}
			.chat-message-receiver {
				flex-grow: 1;
				text-align: left;
				> span {
					margin: 0 5px;
					color: #ccc;
				}
				> a {
					text-decoration: none;
					color: #2da5ff;
				}
			}
			.chat-message-time {
				max-width: 100px;
				color: #ccc;
			}
		}
		.chat-message-text-list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-left: 0;
			.chat-message-text {
				background: #ccecffb3 0% 0% no-repeat padding-box;
				border-radius: 10px 0px 10px 10px;
				padding: 10px 16px;
				color: #4c4c4c;
				font-size: 14px;
				margin-bottom: 8px;
				max-width: 330px;
			}
			&.myself {
				align-items: flex-end;
				.chat-message-text {
					background: #deedfd;
				}
			}
		}
	}
}

.chat-send-file-input {
	display: none;
}
