@import '../../../assets/scss/global.scss';

.custom-datepicker {
	box-shadow: 0px 0px 6px $box-shadow;
}

.react-datepicker__time-container {
	width: 100px;
}

.react-datepicker__time-box {
	width: 100px !important;

}

.react-datepicker__time-list {
	width: 100px !important;
}