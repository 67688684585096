@tailwind utilities;
@layer utilities {
    .sandMovement {
      animation: sandMovement 3s infinite alternate;
    }
  
    @keyframes sandMovement {
      0% {
        top: 4px;
        bottom: 4px;
        height: 20px;
      }
      50% {
        top: 4px;
        bottom: 4px;
        height: 80px;
      }
      100% {
        top: 80px;
        bottom: 80px;
        height: 20px;
      }
    }
  
    .sand-top {
      animation: sandMovement 3s infinite alternate;
    }
  
    .sand-bottom {
      animation: sandMovement 3s infinite alternate reverse;
    }
  }
  