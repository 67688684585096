@import '../../../assets/scss/global.scss';

.metsl-secondary-logo .cls-1 {
    fill: #ec5866;
}

.metsl-secondary-logo .cls-2 {
    fill: #fff;
}

.metsl-secondary-logo .cls-3 {
    fill: #e30117;
}