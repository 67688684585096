$cyan-blue: #1b5984 !important;
$lightcyan-blue: #ccecff80 !important;
$light-sky-blue: #eaf7ff !important;
$white-color: #ffffff !important;
$gray-shade-color: #f3f3f3 !important;
$light-black: #1a1a1a99 !important;
$light-grey: #e7e7e7 !important;
$dark-grey: #4c4c4c99 !important;
$font-color-lightgray: #1a1a1acc !important;
$green: #2b9224 !important;
$dark-green: #2b92244d !important;
$transparent-black: #1a1a1a26 !important;
$light-sky-blue: #ccecff80 !important;
$medium-gray: #1a1a1a99 !important;
$Gunmetal-gray: #4a4b4d !important;
$semi-transparent-black: #00000080 !important;
$gray: #1a1a1a !important;
$dark-grey-opacity: #1a1a1a7f;
$light-gray-opacity: #70707066;
$red-color: #ff0000 !important;
$charcoal: #1a1a1a03 !important;
$box-shadow: #00000029 !important;
$charcoal-gray: #1a1a1a40 !important;
$smokegray: #1a1a1a80 !important;
$silver-gray: #ccc !important;

$roboto-normal: 'Roboto', sans-serif !important;
$font-weight-normal: normal !important;
$font-weight-medium: 600 !important;

.page-content {
	padding: calc(30px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);
}

@media (max-width: 667px) {
	.main-content {
		margin-left: 0;
	}
}

@media print {
    .break-inside-avoid {
        break-inside: avoid;
    }
    .page-break-before-auto {
        page-break-before: auto;
    }
    .page-break-after-auto {
        page-break-after: auto;
    }
}

.p-0 {
	padding: 0px !important;
}

.m-0 {
	margin: 0px !important;
}

.width-30-px {
	width: 30px !important;
}

.width-50 {
	width: 50% !important;
}

.width-48 {
	width: 48% !important;
}

.width-30 {
	width: 30% !important;
}

.width-40 {
	width: 40% !important;
}

.height-30-px {
	height: 30px !important;
}

.fs-8 {
	font-size: 8px !important;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-20 {
	font-size: 20px !important;
}

.column-gap-10px {
	column-gap: 10px;
}

.col-gap-5 {
	column-gap: 5px !important;
}

.column-gap-16 {
	column-gap: 16px;
}

.column-gap-20px {
	column-gap: 20px;
}

.column-gap-40px {
	column-gap: 40px;
}
.row-gap-5 {
	row-gap: 5px;
}
.row-gap-10 {
	row-gap: 10px;
}

.row-gap-15 {
	row-gap: 15px;
}

.row-gap-16 {
	row-gap: 16px;
}

.row-gap-20 {
	row-gap: 16px;
}

.row-gap-30 {
	row-gap: 30px;
}

.flex-2 {
	flex: 2 !important;
}

.flex-3 {
	flex: 3 !important;
}

.min-w-410 {
	min-width: 410px;
}

.max-w-430 {
	max-width: 430px;
}

.max-w-450 {
	max-width: 450px;
}

.max-w-440 {
	max-width: 440px;
}

.box-shadow-1 {
	box-shadow: 0px 0px 6px $box-shadow;
}

.p-7px {
	padding: 7px !important;
}

.pl-30px {
	padding-left: 30px !important;
}

.max-h-637 {
	max-height: 637px;
}

.max-h-568 {
	max-height: 568px;
}

.met-scrollbar::-webkit-scrollbar {
	width: 5px;
	height: 5px !important;
}

.met-scrollbar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 10px;
}

.met-scrollbar::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.flex-basis-50-10 {
	flex-basis: calc(50% - 10px);
}

.max-w-586 {
	max-width: 586px;
}

.max-w-398 {
	max-width: 398px;
}

.min-w-400 {
	min-width: 400px;
}
.h-auto {
	height: auto;
}

.min-w-500 {
	min-width: 500px;
}
.max-h-385 {
	max-height: 385px;
}

.max-w-600 {
	max-width: 600px;
}

.min-w-515 {
	min-width: 515px;
}

.min-width-max-content {
	min-width: max-content;
}

.max-width-max-content {
	max-width: max-content;
}

.error-text {
	@apply text-red-500;
}

.met-text-editor .quill {
	display: flex;
	flex-direction: column;
	min-height: 100px;
}
.met-text-editor .ql-container {
	height: 120px;
	border: none !important;
}
.ql-toolbar {
	order: 2;
	border: none !important;
	box-shadow: 0px 0px 6px $box-shadow;
	width: 70%;
	margin-left: auto;
}

.react-datepicker__year-select::-webkit-scrollbar, .react-datepicker__time-list::-webkit-scrollbar {
	width: 5px;
	height: 10px !important;
}

.react-datepicker__year-select::-webkit-scrollbar-thumb , .react-datepicker__time-list::-webkit-scrollbar-thumb{
	background-color: #ccc;
	border-radius: 10px;
}

.react-datepicker__year-select::-webkit-scrollbar-track , .react-datepicker__time-list::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .loader {
	border: 5px solid #f3f3f3;
	border-top: 5px solid $cyan-blue;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }


  //shedule tab styles
  #scheduling-date-picker .react-datepicker{
	border: none !important;
	box-shadow: 0px 0px 6px $box-shadow;
  }
  	
  #scheduling-date-picker .react-datepicker-popper{
	position: relative !important;
	transform: translate(0px, 10px) !important;
  }
  #scheduling-date-picker .react-datepicker__triangle{
	display: none !important;
  }

  .availability-custom-header .react-datepicker-popper{
	z-index: 100 !important;
  }