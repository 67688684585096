@import '../../../assets/scss/global.scss';

.payment-icon .cls-1 {
  fill: #f7c143;
}

.payment-icon .cls-2 {
  fill: #f8bbbb;
}

.payment-icon .cls-3 {
  fill: #f7c246;
}

.payment-icon .cls-4 {
  fill: #e8a825;
}

.payment-icon .cls-5 {
  fill: #fff;
}

.payment-icon .cls-6 {
  fill: #feffff;
}

.payment-icon .cls-7 {
  fill: #fdfdfe;
}

.payment-icon .cls-8 {
  fill: #fbbdbb;
}

.payment-icon .cls-9 {
  fill: #8493ca;
}

.payment-icon .cls-10 {
  fill: #c1cefd;
}

.payment-icon .cls-11 {
  fill: #dedbdd;
}

.payment-icon .cls-12 {
  fill: #dce2fd;
}

.payment-icon .cls-13 {
  fill: #c3c0c3;
}

.payment-icon .cls-14 {
  fill: #1236b5;
}

.payment-icon .cls-15 {
  fill: #3e67f8;
}

.payment-icon .cls-16 {
  fill: #87a0fb;
}

.payment-icon .cls-17 {
  fill: #88a1fb;
}

.payment-icon .cls-18 {
  fill: #5c6bb0;
}

.payment-icon .cls-19 {
  fill: #484048;
}

.payment-icon .cls-20 {
  fill: #474049;
}

.payment-icon .cls-21 {
  fill: #47404a;
}

.payment-icon .cls-22 {
  fill: #3963f8;
}

.payment-icon .cls-23 {
  fill: #3962f5;
}

.payment-icon .cls-24 {
  fill: #3962f8;
}

.payment-icon .cls-25 {
  fill: #5a576a;
}

.payment-icon .cls-26 {
  fill: #5a7bf9;
}

.payment-icon .cls-27 {
  fill: #3862f8;
}