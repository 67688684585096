@import '../../../assets/scss/global.scss';

.custom-patient-details {
	background-color: $charcoal;
	border: 1px solid;
	border-color: $transparent-black;
	.patient-details-wrapper {
		flex-wrap: wrap;
		.Patient-details-container {
			width: 100%;
			max-width: 500px;
		}
	}
}
