@import '../../../assets/scss/global.scss';

.metsl-primary-logo .cls-1 {
    fill: #019ee0;
}

.metsl-primary-logo .cls-2 {
    fill: #ec5866;
}

.metsl-primary-logo .cls-3 {
    fill: #e30117;
}

.metsl-primary-logo .cls-4 {
    fill: #3ca1e1;
}