.video-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.video-canvas {
	width: 100%;
	height: 100%;
}

.cursor {
	cursor: pointer;
}

.video-footer {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 20px;
}

.video-setting-icons {
	display: flex;
	border: 1px solid #aaaaaa;
	border-radius: 25px;
	opacity: 1;
	padding: 4px 0;
	background-color: rgba(96, 94, 94, 0.4);
}

.minimize-screen {
	position: absolute;
	right: 20px;
	top: -28px;
	z-index: 1055;
}

.minimize-screen-video {
	position: absolute;
	right: 30px;
	top: 25px;
	z-index: 1055;
}

.full-screen-page {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	display: block;
	background-color: white;
	width: 100%;
	height: 100%;
	outline: 0;
}

.full-page-header {
	padding: 16px;
	border-bottom: 1px solid rgb(219, 217, 217);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.full-page-content {
	display: flex;
	align-items: flex-start;
	column-gap: 15px;
}

.full-page-body {
	margin: 16px;
	height: calc(100% - 120px);
}

.half-screen-viewport {
	position: absolute;
	border-radius: 15px;
	box-sizing: border-box;
	width: 450px;
	height: 300px;
	background-color: black;
	display: flex;
	overflow: hidden;
	cursor: move;
	z-index: 10550;
	bottom: 14px;
	left: 14px;
}

.participant-list {
	width: 18%;
	background-color: #aaaaaa;
	border-radius: 20px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #1a1a1a1a;
	padding: 16px;
}

.in-call-messages {
	width: 33%;
	background-color: #aaaaaa;
	border-radius: 20px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #1a1a1a1a;
	padding: 16px;
}

.message-info {
	background: #d0d0d033 0% 0% no-repeat padding-box;
	border: 1px solid #1a1a1a33;
	border-radius: 5px;
	padding: 10px 20px;
	color: #1a1a1ab3;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.chat-message-wrap {
	max-height: 100%;
	overflow: auto;
}

.message-container {
	height: 88%;
}

.zoom-send-message-input:focus {
	box-shadow: none !important;
}

.emoji-picker {
	position: absolute;
	bottom: 80px;
	margin-left: 10px;
}

.viewport {
	position: relative;
	padding: 5vh 0 10vh;
	border-radius: 15px;
	box-sizing: border-box;
	width: 100vw;
	height: 88vh;
	background-color: #000;
	display: flex;
	overflow: hidden;
	.video-container {
		position: relative;
		width: 100%;
		height: 100%;
		&.video-container-attech {
			display: flex;
			align-items: center;
		}
		&.video-container-in-sharing {
			width: 264px;
			flex-shrink: 0;
		}
		&.single-video-container {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.single-video-wrap {
			width: 100%;
			aspect-ratio: 16/9;
			height: auto;
			position: relative;
			max-height: 100%;
		}

		.video-canvas {
			width: 100%;
			height: 100%;
		}
		.self-video {
			position: absolute;
			width: 254px;
			height: 143px;
			top: 50px;
			right: 30px;
			z-index: 2;
			display: none;
			&.single-self-video {
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			&.self-video-show {
				display: block;
			}
		}
		.video-container-wrap {
			flex: 1;
		}
		.user-list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;

			.video-cell {
				min-width: 256px;
				aspect-ratio: 16/9;
				position: relative;
				flex: 1;
				margin: 12px;
				.video-player {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}
	.avatar-list {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		overflow: hidden;
		padding: 0;
		margin: 0;
	}
	.video-operations {
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.avatar-wrap {
		position: absolute;
		pointer-events: auto;
		list-style: none;
		top: 0;
		left: 0;
	}
	.avatar-wrap-dragging {
		z-index: 10;
	}
	.single-view-avatar {
		top: 0;
		left: 0;
	}
	.self-video-tag {
		display: none;
		position: absolute;
	}
}

.guest-name {
	text-align: center;
	padding: 2px;
	border: 1px solid rgb(184, 179, 179);
	border-radius: 50px;
	margin-top: 6px;
}
.guest-name::placeholder {
	text-align: center;
}

.video-timer {
	border: 1px solid #aaaaaa;
	border-radius: 25px;
	opacity: 1;
	padding: 0 25px;
	color: white;
	font-weight: 600;
	background-color: rgba(96, 94, 94, 0.4);
}
